export default [

  // {
  //   title: 'Availability',
  //   route: 'availability',
  //   icon: 'CompassIcon',
  // },
  {
    title: 'Tours',
    route: 'tour',
    icon: 'CompassIcon',
  },
  {
    title: 'Vendors',
    route: 'vendors',
    icon: 'UsersIcon',
  },
  {
    title: 'Add-ons',
    route: 'addons',
    icon: 'PackageIcon',
  },
  {
    title: 'Pickups',
    route: 'pickups',
    icon: 'ClockIcon',
  },
  {
    title: 'Suggestions',
    route: 'suggestions',
    icon: 'FileTextIcon',
  },
  {
    title: 'Locations',
    route: 'locations',
    icon: 'MapIcon',
  },
]
